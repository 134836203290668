<template>
  <el-config-provider
    :locale="zhCn"
    :size="config.size"
    :zIndex="config.zIndex"
    :button="config.button"
  >
    <router-view />
  </el-config-provider>
</template>

<script setup>
import zhCn from "element-plus/es/locale/lang/zh-cn";
import { reactive } from "vue";

const config = reactive({
  size: "default",
  zIndex: 2000,
  button: {
    autoInsertSpace: false,
  },
});
</script>

<style lang="scss"></style>
