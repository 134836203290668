export default [
  {
    path: "/",
    name: "home",
    meta: {
      title: "选品",
    },
    component: () => import(/* webpackChunkName: "selection" */ "@/views/selection/index.vue"),
  },
];
