import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./routes";
import NProgress from "nprogress";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  document.title = to.meta.title
    ? `${process.env.VUE_APP_TITLE} - ${to.meta.title}`
    : `${process.env.VUE_APP_TITLE}`;
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
