import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import "@/style/app.scss";
import "@/style/reset.scss";

const app = createApp(App);

app.use(router).use(ElementPlus).use(ElementPlus);

//统一注册el-icon图标
for (let icon in ElementPlusIconsVue) {
  app.component(`${icon}`, ElementPlusIconsVue[icon]);
}

app.mount("#app");
